import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(data.office_id), style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getFiscalYear(data.fiscal_year_id), style: 'td', alignment: 'left' }
          ]
        ]
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['10%', '3%', '25%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        // option
          let allRows = []
          allRows = [
            [
              // { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tpm.training_quarter'), style: 'th', alignment: 'center' },
              { text: vm.$t('external_research.activity'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tpm.quarterly_goals'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tpm.possible_training_date'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tpm.upazila_venue'), style: 'th', alignment: 'center' }
            ]
          ]
          // table body
          data.details.filter((item, optionIndex) => {
            const rowItem = [
              { text: vm.getQuarterName(item.training_quarter_id), style: 'td', alignment: 'center' },
              { text: vm.getTrainingTypeName(item.training_type_id), style: 'td', alignment: 'center' },
              { text: vm.$n(item.quarterly_goals), style: 'td', alignment: 'center' }
            //   { text: item.training_quarter_id, style: 'td', alignment: 'center' }
            //   { text: item.training_quarter_id, style: 'td', alignment: 'center' }
            ]
            const dates = []
            item.dates.filter((item2, optionIndex2) => {
                dates.push([vm.dateFormation(item2.start_date, item2.end_date)])
            })
            const venue = []
            item.dates.filter((item2, optionIndex2) => {
                venue.push([vm.$i18n.locale === 'en' ? item2.venu_name : item2.venu_name_bn])
            })
            rowItem.push(
                [
                    {
                        table: {
                            body: dates
                        },
                        layout: 'noBorders'
                    }
                ]
            )
            rowItem.push(
                [
                    {
                        table: {
                            body: venue
                        },
                        layout: 'noBorders'
                    }
                ]
            )
            allRows.push(rowItem)
          })
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['20%', '20%', '10%', '30%', '20%'],
              body: allRows
            }
          })
          pdfContent.push({ text: '', style: 'tableMargin' })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 9,
            bold: true,
            margin: [5, -25, 5, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
